/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './assets/css/App.css';
import './assets/css/Home.css';
import justice from './assets/img/blindjustice.jpeg'
import tower from './assets/img/uniontower1.jpg'

function App() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_vgjr7x8', 'template_wpau7kn', form.current, 'wR3CmbLUOusPnqPiw')
      .then((result) => {
        console.log(result.text);
        alert("Contact Info Submitted!")
      }, (error) => {
        alert(error.text);
      });
    document.getElementById("contact-form").reset();
    document.getElementById('contactButton').addEventListener('click', function () {
      <script>
        fbq('track', 'Contact');
      </script>
    }, false);
  };


  return (
    <>
      <header>
        <div className='header'>
          <div className="display-1">
            Isabel P. Posso, Esq.
            <h3>Attorney at Law, PC</h3>
          </div>
        </div>
      </header>
      <main className='container-fluid'>
        <div className="row ps-light p-0 m-0">
          <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center p-5">
            <img src={tower} width="75%" alt="Union Tower One Building" />
          </div>

          <div className="col-sm-12 col-md-6 d-flex flex-column text-center align-items-md-start justify-content-center">
            <div className="display-4">
              About Us
            </div>
            <div className="lead col-lg-10 text-center text-md-start">
              Family law practice focused on divorce, custody, child support litigation and settlement with decades of experience. Practice also focuses on criminal defense of felonies, misdemeanors, and traffic. Located in Lakewood, CO.
            </div>
            <div className="lead col-lg-10 text-center text-md-start">
              Práctica de derecho familiar que se enfoca en divorcio, custodia, litigios de manutención de niños y acuerdos con décadas de experiencia. La práctica también se enfoca en la defensa criminal de delitos graves, delitos menores y tráfico.</div>
          </div>

          <div className="row ps-dark p-0 m-0">
            <div className="parallax"></div>
          </div>

          <div className="row ps-light p-0 m-0">
            <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center p-5">
              <img src={justice} width="75%" alt="blind justice" />
            </div>

            <div className="col-sm-12 col-md-6 d-flex flex-column align-items-md-start justify-content-center text-black">
              <div className="display-5 text-center text-md-start">Contact Us:</div>
              <span>Phone: 303-716-8500</span>
              <span>Fax: 303-845-9504</span>
              <span>Email: isabelposso@comcast.net</span>
              <span>Address:</span>
              <span>165 S. Union Blvd., Ste 456</span>
              <span>Lakewood, CO 80228</span>
              <span>Or use the contact form below!</span>
              <span>Se habla Español</span>
            </div>
            <div className="row ps-dark p-0 m-0">
              <div className="col-sm-12 col-md-6 d-flex flex-column align-items-start text-black justify-content-center">
                <div className='col-sm-12 col-md-10 d-flex flex-column align-items-center text-black justify-content-center'>
                  <form ref={form} onSubmit={sendEmail} id="contact-form">
                    <div className="display-6 text-center text-md-start">Contact Form:</div>
                    <div className="form-group">
                      <label for="Name">Name/Nombre:</label>
                      <input type="text" name="user_name" className="form-control" placeholder="Enter Full Name" />
                      <label>Email Address/Correro Electronico:</label>
                      <input type="email" className="form-control" name="user_email" aria-describedby="emailHelp" placeholder="Enter Email" />
                      <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small><br />
                      <label>Message/Mensaje:</label>
                      <textarea className="form-control" name='message' rows="3"></textarea>
                    </div>
                    <button type="submit" button id="contactButton" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center p-5">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12276.724399291852!2d-105.1344696!3d39.7131126!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b83f8fa31f12d%3A0xe50afabd4daa2398!2sPosso%20Isabel%20P!5e0!3m2!1sen!2sus!4v1680999866867!5m2!1sen!2sus" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              <p>© 2023 Isabel P. Posso, Esq., P.C.</p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default App;
